import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

interface Props {
  siteTitle: string
}

const Header: React.FC<Props> = ({ siteTitle }) => {
  return (
    <header className="px-2 md:px-4 fixed shadow top-0 w-full h-12 flex items-center z-50 bg-white justify-between">
      <div className="flex items-center height-full">
        <Link className="flex h-full items-center md:mr-4" to="/">
          <img className="block h-6 pr-2" src="/images/logo.svg" />
          <div className="text-xl font-display hidden md:block">
            {siteTitle}
          </div>
        </Link>
        <Link
          to="/about"
          className="text-l px-3 md:px-6"
          activeClassName="underline"
        >
          О нас
        </Link>
        <Link
            to="/specproekt"
            className="text-l px-3 md:px-6"
            activeClassName="underline"
        >
          Спецпроект
        </Link>
        <Link
          to="/participate"
          className="text-l px-3 md:px-6"
          activeClassName="underline"
        >
          Участвовать
        </Link>
      </div>
      <div className="flex items-center justify-end">
        <a
          className="block mr-4"
          href="https://www.facebook.com/drugmoimishka"
          target="__blank__"
        >
          <img className="h-8 w-8" src="/images/facebook.svg" />
        </a>
        <a
          className="block"
          href="https://www.instagram.com/drugmoimishka/"
          target="__blank__"
        >
          <img className="h-8 w-8" src="/images/instagram.svg" />
        </a>
      </div>
    </header>
  )
}

export default Header
