import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Header from "../components/header"
import LayoutContextProvider, { LayoutContext } from "./LayoutContext"

interface Props {
  children: any
}

const Layout: React.FC<Props> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <LayoutContextProvider>
      {(context: LayoutContext) => (
        <React.Fragment>
          <Header siteTitle={data.site.siteMetadata.title} />
          <main className="mt-12">{children}</main>
          <footer className="text-center mt-8">
            © {new Date().getFullYear()}, Наташа Усачева
          </footer>
        </React.Fragment>
      )}
    </LayoutContextProvider>
  )
}

export default Layout
