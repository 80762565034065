import React, { useState } from "react"

interface LayoutContext {
  layoutState: {
    showNav: boolean
  }
  toggleNav: () => void
}

const defaultContextValue: LayoutContext = {
  layoutState: {
    showNav: false,
  },
  toggleNav: () => {},
}

const { Provider, Consumer: LayoutContextConsumer } = React.createContext(
  defaultContextValue
)

const LayoutContextProvider: React.FC<LayoutContext> = props => {
  const [state, setState] = useState(defaultContextValue)

  const toggleNav = () =>
    setState({
      ...state,
      layoutState: {
        ...state.layoutState,
        showNav: !state.layoutState.showNav,
      },
    })
  return <Provider value={{ ...state, toggleNav }}>{props.children}</Provider>
}

export {
  LayoutContextConsumer as default,
  LayoutContextProvider,
  LayoutContext,
}
